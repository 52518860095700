<template>
  <base-section id="mb-info">
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container>
        <v-row class="d-flex justify-center">
          <base-img
            src="./assets/img/MBLogoUnnamed.png"
            contain
            max-width="250"
            width="100%"
          />
        </v-row>
        <v-row class="d-flex justify-center">
          <base-heading
            title="Monica Bachmann"
            :override="false"
            weight="light"
            mobile-size="heading"
          />
        </v-row>
        <br>
        <base-section-heading>
          Du möchtest dein Potential und deine Aufgaben in diesem Leben erkennen, mehr Bewusstsein für dich erlangen, Muster verstehen und Klarheit gewinnen? <br><br>
          Dann lade ich dich herzlich ein, dich mit mir gemeinsam auf deine ganzheitliche Entdeckungsreise zu begeben, damit du die gewünschte Änderung in deinem Leben umsetzen und so deinen Weg wieder mit voller Freude und mehr Sicherheit gehen kannst.
        </base-section-heading>
        <v-row class="d-flex justify-center">
          <video
            controls
            playsinline
            width="700px"
          >
            <source
              src="../../../public/assets/img/V2-ifap-Astrologische Psychologie-Film-Bachmann-DE_1.mp4"
              type="video/mp4"
            >
          </video>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMbInfo',

    data: () => ({
    }),
  }
</script>

<style scoped>
#mb-info {
  padding-top: 0px !important;
}
</style>
